import React, { useMemo, useState } from 'react';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { useTableOrMobile } from '../../../hooks';
import {
  cannonStyled,
  NSH3,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import { Button, CustomDropdown, CustomInput } from '../../../atoms';
import { ArrowRight as ArrowRight_ } from 'baseui/icon';
import { states } from '../RegisterSafeFormBySteps/helpers/states';
import PhoneIcon from '../../../assets/phone.svg';
import invalidCharsInput from '../../../atoms/js/invalidCharsInput';
import InfoTool from '../../../atoms/Button/InfoTool';

// STYLED COMPONENTS

const FlexContainer = cannonStyled(
  'div',
  ({ direction }: { direction?: 'column' | 'row' }) => ({
    display: 'flex',
    flexDirection: direction,
  })
);

const SubmitButtonContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    marginTop: '10vh',
    width: isMobile ? '100%' : '50%',
  })
);

const MainContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: 0,
    marginTop: isMobile ? '55px' : '70px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  })
);

const PhoneIconStyled = cannonStyled(PhoneIcon, (props) => ({
  stroke: props.$theme.cannonColors.secondarySilver,
}));

// INTERFACES

export interface ILostAssistanceForm {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zipCode: string;
  electronicSignature: string;
  safeModel: string;
  serialNumber: string;
  electronicSignatureCertify: string;
  ownerCertify: string;
  comesFrom: string;
  keyNumber: string;
  [key: string]: string | boolean;
}

interface Props {
  onFormSentSuccesfully: (value: ILostAssistanceForm) => void;
}

export const LostAssistanceForm = ({ onFormSentSuccesfully }: Props) => {
  // HOOKS
  const [css, theme] = useCannonStyletron();
  const [isMobile, isTablet] = useTableOrMobile();
  const statesFormated = useMemo(() => {
    return states.map((s, index: number) => {
      s.id = index.toString();
      return s;
    });
  }, [states]);

  // STATE

  const [submitClicked, setSubmitClicked] = useState(false);
  const [lostAssitanceObj, setLostAssitanceObj] = useState(
    {} as ILostAssistanceForm
  );
  const [emailInvalid, setEmailInvalid] = useState(false);
  const [invalidSerialNumber, setInvalidSerialNumber] = useState(false);
  const [electronicSignatureChecked, setElectronicSignatureChecked] =
    useState(false);
  const [ownerChecked, setOwnerChecked] = useState(false);

  // EFFECTS
  const {
    firstName = '',
    lastName = '',
    phone = '',
    email = '',
    electronicSignature = '',
    state = '',
    city = '',
    addressLine1 = '',
    addressLine2 = '',
    zipCode = '',
    safeModel = '',
    serialNumber = '',
    keyNumber = '',
    electronicSignatureCertify = '',
    ownerCertify = '',
  } = lostAssitanceObj;

  // HANDLERS & FUNCTIONS

  function handleChange(value: string, key: string) {
    switch (key) {
      case 'email':
        const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          value
        );
        setEmailInvalid(!valid);
        break;
      case 'ownerCertify':
        value = ownerChecked
          ? '-' : 'I certify that this is my electronic signature';
        break;
      case 'electronicSignatureCertify':
        value = electronicSignatureChecked ?
          '-': 'I am the owner of the Cannon Product I am requestion a key Replacement';
        break;
      default:
        break;
    }
    if (key === 'email') {
      const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value);
      setEmailInvalid(!valid);
    }
    const tmp = { ...lostAssitanceObj };
    tmp[key] = value;
    setLostAssitanceObj(tmp);
  }
  // RENDER
  return (
    <MainContainer isMobile={isMobile} aria-label='Complete your information'>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (
            !emailInvalid &&
            electronicSignatureCertify != '' &&
            ownerCertify != '' &&
            (keyNumber != '' || serialNumber != '')
          ) {
            onFormSentSuccesfully(lostAssitanceObj);
          }
        }}
        style={{ width: '100%', marginBottom: '80px' }}
      >
        <TWParagraph3
          color={theme.cannonColors.primarySolidBlack}
          weight={700}
          align={'left'}
          style={{ fontSize: '16px' }}
        >
          Owner information
        </TWParagraph3>
        <FlexContainer>
          <CustomInput
            noMarginLeft
            errorMessage='Field Required'
            error={firstName === '' && submitClicked}
            positive={firstName !== ''}
            label='First name'
            placeholder='E.g. John'
            value={firstName}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, 'firstName')
            }
            required
            requiredStyle
          />
          <div style={{ width: '20px' }} />
          <CustomInput
            noMarginLeft
            errorMessage='Field Required'
            error={lastName === '' && submitClicked}
            positive={lastName !== ''}
            label='Last name'
            placeholder='E.g. Doe'
            value={lastName}
            onChange={(e: any) =>
              handleChange(e.currentTarget.value, 'lastName')
            }
            required
            requiredStyle
          />
        </FlexContainer>
        <CustomInput
          noMarginLeft
          errorMessage={
            email !== '' && emailInvalid ? 'Email Invalid' : 'Field Required'
          }
          error={(email === '' && submitClicked) || emailInvalid}
          positive={email !== '' && !emailInvalid}
          label='Email'
          placeholder='Enter email'
          value={email}
          onChange={(e: any) => handleChange(e.currentTarget.value, 'email')}
          required
          requiredStyle
        />
        <CustomInput
          noMarginLeft
          startEnhancer={() => <PhoneIconStyled />}
          errorMessage={phone === '' ? 'Field required' : 'Follow the format'}
          type='number'
          placeholder='+1-000-000-0000'
          error={
            (phone === '' && submitClicked) ||
            (phone.length < 10 && phone !== '')
          }
          label='Phone'
          value={phone}
          positive={phone !== ''}
          onKeyDown={(e: any) => invalidCharsInput(e, ['e', '-'])}
          onChange={(e: any) => handleChange(e.currentTarget.value, 'phone')}
          required
          requiredStyle
        />
        <CustomInput
          label='Electronic Signature'
          value={electronicSignature}
          onChange={(e) =>
            handleChange(e.currentTarget.value, 'electronicSignature')
          }
          placeholder='Personal electronic signature'
          noMarginLeft
          required
          requiredStyle
          errorMessage='Field required'
          error={electronicSignature === '' && submitClicked}
          positive={electronicSignature !== ''}
        />
        <TWParagraph3
          color={theme.cannonColors.primarySolidBlack}
          weight={700}
          align={'left'}
          style={{ fontSize: '16px', marginTop: '40px' }}
        >
          Address
        </TWParagraph3>
        <CustomInput
          noMarginLeft
          errorMessage='Field Required'
          error={addressLine1 === '' && submitClicked}
          label='Address Line 1'
          placeholder='E.g. 90 Bedford Street'
          value={addressLine1}
          onChange={(e: any) =>
            handleChange(e.currentTarget.value, 'addressLine1')
          }
          positive={addressLine1 !== ''}
          required
          requiredStyle
        />
        <CustomInput
          noMarginLeft
          label='Address Line 2'
          placeholder='Apartment number or letter'
          value={addressLine2}
          onChange={(e: any) =>
            handleChange(e.currentTarget.value, 'addressLine2')
          }
        />
        <FlexContainer id='location'>
          <CustomInput
            noMarginLeft
            label='City'
            placeholder='E.g. New York'
            value={city}
            onChange={(e: any) => handleChange(e.currentTarget.value, 'city')}
          />
          <CustomDropdown
            clearable={false}
            aria-labelledby='state'
            aria-label='state input'
            placeholder='Choose an option'
            background='white'
            label='State'
            onChange={(value) => handleChange(value[0].label, 'state')}
            options={statesFormated}
            value={state !== '' ? [{ label: state }] : []}
            positive={state !== ''}
          />
        </FlexContainer>
        <CustomInput
          noMarginLeft
          aria-label='Zip Code'
          type='number'
          min={0o765}
          max={99999}
          error={zipCode.length < 5 && zipCode.length > 0}
          errorMessage='Just five digits allowed'
          placeholder='Enter a 5 digit zip code'
          label='Zip Code'
          value={zipCode}
          onKeyPress={(e: any) => {
            if (e.target.value.length > 4) {
              e.preventDefault();
            }
          }}
          onKeyDown={(e: any) => invalidCharsInput(e, ['e', '-', '+'])}
          onChange={(e: any) => handleChange(e.currentTarget.value, 'zipCode')}
          positive={zipCode !== ''}
        />
        <TWParagraph3
          color={theme.cannonColors.primarySolidBlack}
          weight={700}
          align={'left'}
          style={{ fontSize: '16px', marginTop: '40px' }}
        >
          Product
        </TWParagraph3>
        <CustomInput
          label='Product Model Number'
          value={safeModel}
          onChange={(e) => handleChange(e.currentTarget.value, 'safeModel')}
          placeholder='Product Model Number'
          noMarginLeft
          errorMessage='Field required'
          error={safeModel === '' && submitClicked}
          positive={safeModel !== ''}
          required
          requiredStyle
        />
        <FlexContainer>
          <CustomInput
            label='Serial Number (for safes only)'
            value={serialNumber}
            onChange={(e) =>
              handleChange(e.currentTarget.value, 'serialNumber')
            }
            placeholder='For safes only'
            positive={serialNumber !== '' && !invalidSerialNumber}
            error={
              (submitClicked && keyNumber === '' && serialNumber === '') ||
              invalidSerialNumber
            }
            errorMessage='Fill Serial or Key Number field, please'
            noMarginLeft
          />
          <CustomInput
            label='Key Number'
            value={keyNumber}
            onChange={(e) => {
              handleChange(e.currentTarget.value, 'keyNumber');
              //   try {
              //     if (/^[0-9-]*$/.test(e.currentTarget.value)) {
              //       setInvalidSerialNumber(false);
              //       handleChange(
              //         e.currentTarget.value
              //           .match(/\d{4}(?=\d{2,3})|\d+/g)
              //           .join('-'),
              //         'serialNumber'
              //       );
              //     } else {
              //       setInvalidSerialNumber(true);
              //     }
              //   } catch (e) {
              //     console.log('Error', e);
              //   }
            }}
            placeholder='Key Number'
            positive={safeModel !== '' && !invalidSerialNumber}
            error={
              (submitClicked && keyNumber === '' && serialNumber === '') ||
              invalidSerialNumber
            }
            errorMessage='Fill Serial or Key Number field, please'
          />
        </FlexContainer>
        <TWParagraph3
          color={theme.cannonColors.primarySolidBlack}
          weight={700}
          align={'left'}
          style={{ fontSize: '16px', marginTop: '40px' }}
        >
          Please confirm
        </TWParagraph3>
        <Checkbox
          checked={electronicSignatureChecked}
          onChange={(e: any) => {
            e.stopPropagation();
            setElectronicSignatureChecked(e.target.checked);
            handleChange(e.target.checked, 'electronicSignatureCertify');
          }}
          overrides={{
            Checkmark: {
              style: {
                borderLeftColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderRightColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderTopColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderBottomColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
                borderWidth: '2px',
                fontSize: '2px',
                margin: '25px 0 0 5px',
              },
            },
          }}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          <TWParagraph3
            align='left'
            weight={400}
            color={
              submitClicked && !electronicSignatureCertify
                ? theme.cannonColors.secondaryCarmineLight
                : theme.cannonColors.secondarySilverDark
            }
            $style={{
              fontSize: isMobile ? '14px' : '16px',
              margin: '20px 0 0 0',
            }}
          >
            By checking this box I certify that this is my electronic signature.
          </TWParagraph3>
        </Checkbox>
        {submitClicked && !electronicSignatureCertify && (
          <TWParagraph3
            align='left'
            weight={700}
            color={theme.cannonColors.secondaryCarmine}
            $style={{
              margin: '5px 0 0 34px',
            }}
          >
            You must accept in order to continue
          </TWParagraph3>
        )}
        <Checkbox
          checked={ownerChecked}
          onChange={(e: any) => {
            e.stopPropagation();
            setOwnerChecked(e.target.checked);
            handleChange(e.target.checked, 'ownerCertify');
          }}
          overrides={{
            Checkmark: {
              style: {
                borderLeftColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderRightColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderTopColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderBottomColor:
                  submitClicked && !electronicSignatureCertify
                    ? theme.cannonColors.secondaryCarmineLight
                    : theme.cannonColors.primaryBlue,
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomLeftRadius: '5px',
                borderBottomRightRadius: '5px',
                borderWidth: '2px',
                fontSize: '2px',
                margin: '15px 0 0 5px',
              },
            },
          }}
          labelPlacement={LABEL_PLACEMENT.right}
        >
          <TWParagraph3
            align='left'
            weight={400}
            color={
              submitClicked && !electronicSignatureCertify
                ? theme.cannonColors.secondaryCarmineLight
                : theme.cannonColors.secondarySilverDark
            }
            $style={{
              fontSize: isMobile ? '14px' : '16px',
              margin: '13px 0 0 0',
            }}
          >
            I declare under penalty of perjury (under the laws of the United
            States of America) that I am the owner of the Cannon product I am
            requesting a key replacement or combination confirmation for and
            that this replacement will not be used in any unlawful manner.
          </TWParagraph3>
        </Checkbox>
        {submitClicked && !electronicSignatureCertify && (
          <TWParagraph3
            align='left'
            weight={700}
            color={theme.cannonColors.secondaryCarmine}
            $style={{
              margin: '5px 0 0 34px',
            }}
          >
            You must accept in order to continue
          </TWParagraph3>
        )}
        <FlexContainer
          direction='row'
          style={{ justifyContent: 'center', width: '100%' }}
        >
          <SubmitButtonContainer isMobile={isMobile}>
            <Button.RoundedButton
              onClick={() => {
                setSubmitClicked(true);
              }}
              endEnhancer={() => <ArrowRight_ size={26} />}
              size='compact'
              type='submit'
              color='primary'
              fullWidth
            >
              Submit my request
            </Button.RoundedButton>
          </SubmitButtonContainer>
        </FlexContainer>
      </form>
    </MainContainer>
  );
};

export default LostAssistanceForm;
