import React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import {
  cannonStyled,
  NSH3,
  TWParagraph3,
  useCannonStyletron,
} from '../../../theme';
import { useAPIURL, useTableOrMobile } from '../../../hooks';
import { IFileImage } from '../../../@types';
import ReactMarkdown from 'react-markdown';
import { Button } from '../../../atoms';
import ArrowRight from '../../../assets/arrow-right.svg';
import ArrowDown from '../../../assets/arrow-down.svg';

// QUERY

const query = graphql`
  query {
    strapiLostAssistance {
      applicationSuccessfulMessage
      applicationSuccessfulSteps {
        subtitle
        title
        icon {
          url
        }
      }
    }
  }
`;

interface Props {}

interface IStepApplication {
  title: string;
  subtitle: string;
  icon: IFileImage;
}

export interface ILostAssistanceApplication {
  applicationSuccessfulMessage: string;
  applicationSuccessfulSteps: IStepApplication[];
}
interface IData {
  strapiLostAssistance: ILostAssistanceApplication;
}

const GridContainer = cannonStyled(
  'div',
  ({ isMobile, isTablet }: { isMobile: boolean; isTablet: boolean }) => ({
    display: 'grid',
    gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
    gridGap: isMobile ? '0px' : '25px',
    paddingTop: isMobile ? '60px' : '120px',
    paddingBottom: '80px',
  })
);

const StepContainer = cannonStyled(
  'div',
  (props: any & { isLast?: boolean; isMobile?: boolean }) => ({
    height: 'fit-content',
    padding: props.isMobile ? '30px 0px' : '30px',
    background: props.isLast ? props.$theme.cannonColors.primaryWhiteDark : '',
    ...(props.isLast
      ? {
          textAlign: 'left',
          padding: '30px',
        }
      : {}),
  })
);

const Image = cannonStyled('img', ({ isMobile }: { isMobile: boolean }) => ({
  height: isMobile ? '80px' : '100px',
  paddingBottom: '10px',
}));

const ArrowContainer = cannonStyled('div', (props) => ({
  background: props.$theme.cannonColors.primaryBlue,
  width: '32px',
  height: '32px',
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const MainContainer = cannonStyled(
  'div',
  ({ isMobile }: { isMobile: boolean }) => ({
    padding: 0,
    marginTop: isMobile ? '15px' : '150px',
    width: '100%',
    height: '100%',
  })
);

const FlexContainer = cannonStyled(
  'div',
  ({ direction }: { direction?: 'column' | 'row' }) => ({
    display: 'flex',
    flexDirection: direction,
  })
);

export const LostAssistanceSuccessful = ({ email = '' }: { email: string }) => {
  // HOOKS
  const data = useStaticQuery<IData>(query);
  const [isMobile, isTablet] = useTableOrMobile();
  const [css, theme] = useCannonStyletron();
  const API_URL = useAPIURL();

  // STATE

  // HANLDERS && FUNCTIONS

  // RENDER

  const { strapiLostAssistance = {} as ILostAssistanceApplication } = data;
  const {
    applicationSuccessfulMessage,
    applicationSuccessfulSteps = [] as IStepApplication[],
  } = strapiLostAssistance;

  return (
    <MainContainer isMobile={isMobile} role='region' aria-label="Lost Assistance Form Successful">
      <ReactMarkdown
        components={{
          h2: ({ node, ...props }) => (
            <NSH3
              weight='bold'
              align='center'
              $style={{
                marginBottom: '20px',
                fontSize: '31px',
              }}
              color={theme.cannonColors?.primaryBlue}
              {...props}
            />
          ),
          p: ({ node, ...props }) => (
            <TWParagraph3
              color={theme.cannonColors.secondarySilverDark}
              weight={400}
              align='center'
              $style={{ fontSize: '20px' }}
              {...props}
            />
          ),
          a: ({ node, ...props }) => (
            <a
              style={{
                color: theme.cannonColors.secondaryOrange,
                fontWeight: 700,
              }}
              {...props}
            >
              {email}
            </a>
          ),
        }}
      >
        {applicationSuccessfulMessage}
      </ReactMarkdown>
      <GridContainer isMobile={isMobile} isTablet={isTablet}>
        {applicationSuccessfulSteps.map(
          (applicationSuccessfulStep: IStepApplication, index: number) => (
            <FlexContainer
              direction={isMobile ? 'column' : 'row'}
              style={{ justifyContent: 'space-around' }}
              
            >
              <StepContainer isMobile={isMobile} >
                <Image
                  isMobile={isMobile}
                  src={API_URL + applicationSuccessfulStep?.icon?.url}
                  alt='succesful icon'
                />
                <TWParagraph3
                  color={theme.cannonColors.primaryBlue}
                  weight={700}
                  align='center'
                  $style={{ fontSize: '20px', marginBottom: '15px' }}
                >
                  {applicationSuccessfulStep.title}
                </TWParagraph3>
                <TWParagraph3
                  color={theme.cannonColors.secondarySilverDark}
                  weight={400}
                  align='center'
                  $style={{ fontSize: '16px' }}
                >
                  {applicationSuccessfulStep.subtitle}
                </TWParagraph3>
              </StepContainer>
              {index >= 0 && index < applicationSuccessfulSteps.length - 1 && (
                <FlexContainer
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                  direction={isMobile ? 'column' : 'row'}
                >
                  <ArrowContainer>
                    {isMobile ? (
                      <ArrowDown stroke='white' />
                    ) : (
                      <ArrowRight
                        style={{ width: '20px', padding: '10px' }}
                        stroke='white'
                      />
                    )}
                  </ArrowContainer>
                </FlexContainer>
              )}
            </FlexContainer>
          )
        )}
      </GridContainer>
      <FlexContainer direction='row' style={{ justifyContent: 'center' }}>
        <div style={{ width: isMobile ? 'fit-content' : 'min(30vw)' }}>
          <Button.RoundedButton
            onClick={() => (window.location.href = '/')}
            size='compact'
            color='primary'
            fullWidth
          >
            Back to home
          </Button.RoundedButton>
        </div>
      </FlexContainer>
    </MainContainer>
  );
};

export default LostAssistanceSuccessful;
