import React, { useEffect, useState } from 'react';
import { PageProps } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { cannonStyled } from '../theme';
import { CannonBreadcrumbs } from '../atoms';
import { ResponsiveContainer } from '../atoms/Container';
import { useCoreAPIURL, useTableOrMobile } from '../hooks';
import LostAssistanceForm, {
  ILostAssistanceForm,
} from '../components/sections/LostAssistanceForm';
import scrollTo from 'gatsby-plugin-smoothscroll';
import LostAssistanceSuccessful from '../components/sections/LostAssistanceForm/LostAssistanceFormSuccessful';

interface Props {}

const BreadCrumbsContainer = cannonStyled(
  'div',
  (props: { isMobile: boolean }) => ({
    position: 'absolute',
    top: props.isMobile ? '10px' : '30px',
    left: props.isMobile ? '10px' : '75px',
    width: '100%',
    textAlign: 'left',
  })
);

const MainWrapper = cannonStyled(
  Wrapper,
  (props: { isMobile: boolean; isTablet?: boolean }) => ({
    display: 'flex',
    maxWidth: '1280px',
    width: props.isMobile ? '95%' : props.isTablet ? '90%' : '100%',
    justifyContent: 'center',
    padding: props.isMobile ? '100px 10px' : '50px 10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowX: 'hidden',
    textAlign: 'center',
  })
);

export const LostAssistanceApplication: React.FC<PageProps> = (
  props: PageProps
) => {
  // HOOKS
  const [isMobile, isTablet] = useTableOrMobile();
  const coreAPI_URL = useCoreAPIURL();
  const [email, setEmail] = useState('');

  // STATE

  const [formSubmited, setFormSubmited] = useState(false);

  //EFFECTS

  useEffect(() => {
    if (formSubmited === true) {
      window.location.hash = 'submited';
    }
  }, [formSubmited]);

  // HANLDERS && FUNCTIONS

  async function handleFormSubmited(value: ILostAssistanceForm) {
    setFormSubmited(true);
    scrollTo('#main');
    setEmail(value.email)
    const response = await fetch(coreAPI_URL + '/lost-combination', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: value }),
    })
      .then((r) => r.json())
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.log('Error lost combination', e);
      });
    console.log('Lost combination response:', response);
  }

  // RENDER
  return (
    <LayoutProvider seo locationSearch={props.location.search}>
      <MainWrapper isMobile={isMobile} isTablet={isTablet} noScroll id='main'>
        <BreadCrumbsContainer isMobile={isMobile}>
          <CannonBreadcrumbs
            pages={[
              { title: 'Home', href: '/' },
              { title: 'Support', href: '' },
              {
                title: 'Lost Combination Assistance',
                href: '/lost-assistance',
              },
              { title: 'Application', href: '/lost-assistance-application' },
            ]}
          />
        </BreadCrumbsContainer>
        <ResponsiveContainer>
          {props?.location?.href?.includes('submited') || false ? (
            <LostAssistanceSuccessful email={email} />
          ) : (
            <LostAssistanceForm onFormSentSuccesfully={handleFormSubmited} />
          )}
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default LostAssistanceApplication;
